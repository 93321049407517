import React, { FunctionComponent, useState, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as EMAIL } from "static/media/email.svg";
import { makeStyles } from "@material-ui/core/styles";

type emailType = {
  value?: string;
  autoFocus?: boolean;
  helperText?: any;
  color?: string;
  label?: string;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  variant?: any;
  error?: any;
  marginLeft?: string;
  borderColor?: string;
  marginTop?: string;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => any;
  name?: string;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => any;
  backgroundColor?: string;
  iconWidth?: string;
};

const useStyles = makeStyles({
  text: {
    width: (props: any) => props.width,
    maxWidth: "450px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: (props: any) => props.borderColor,
        borderWidth: "1px",
      },
      "& .MuiInputBase-input": {
        height: "45px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "0",
      },
    },
    marginTop: (props: any) => props.marginTop,
  },
  backgroundColor: {
    backgroundColor: (props: any) => props.backgroundColor,
    height: "45px",
    borderRadius: "10px",
    fontFamily: "'Avenir Book', sans-serif",
  },
  label: {
    color: (props: any) => props.color,
    top: -10,
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: 15,
  },
  icon: {
    color: "gray",
    marginLeft: (props: any) => props.marginLeft,
    width: (props: any) => props.iconWidth,
    height: (props: any) => props.iconWidth,
  },
  underline: {
    "&:after": {
      borderBottom: "1.5px solid #000",
    },
    "&.MuiInput-error": {
      borderBottom: "1.5px solid #f44336",
    },
  },
  helperText: {
    color: "rgb(209, 44, 0)",
    fontSize: "13px",
    textAlign: "center",
  },
});

const Email: FunctionComponent<emailType> = ({
  value = "",
  name = "email",
  autoFocus = false,
  helperText = "",
  label = "Email",
  color = "#fff",
  width = "100%",
  variant = "standard",
  error = false,
  disabled = false,
  marginLeft = "10px",
  borderColor = "#000",
  placeholder = "your@email.com",
  marginTop,
  onChangeHandler,
  handleBlur,
  backgroundColor = "#fff",
  iconWidth = "29px",
}) => {
  const [emailError, setEmailError] = useState<string>("");
  const classes = useStyles({
    color,
    width,
    borderColor,
    marginLeft,
    marginTop,
    backgroundColor,
    iconWidth,
  });

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@+]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    console.log("email", email);
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
    if (onChangeHandler) {
      onChangeHandler(e);
    }
  };

  return (
    <TextField
      id="email"
      name={name}
      label={label}
      type="email"
      margin="normal"
      placeholder={placeholder}
      defaultValue={value}
      value={value}
      error={!!emailError || error}
      fullWidth
      disabled={disabled}
      helperText={emailError || helperText}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      className={classes.text}
      InputLabelProps={{
        className: classes.label,
      }}
      autoComplete="email"
      autoFocus={autoFocus}
      variant={variant}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        className: classes.backgroundColor,
        classes: { root: classes.underline },
        startAdornment: (
          <InputAdornment position="start">
            <EMAIL className={classes.icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Email;
